<template>
  <!-- 정보수집시 -->
  <button
    v-if="collectType"
    class="card_download"
    data-tiara-layer="download_button"
    :data-tiara-action-name="`${tiaraName}_다운로드_클릭`"
    @click="
      downloadOpen.openDownloadLayer(
        props.cardData.title,
        props.cardData.linkUrl,
        props.cardData.infoType,
        props.cardData.serviceName,
      )
    "
  >
    <div class="wrap_title">
      <div class="wrap_thumb">
        <img
          :src="
            generateWebpImage(
              props.cardData.thumbnail === ''
                ? 'https://t1.kakaocdn.net/dkt_corp/service/ico_nodata.png'
                : props.cardData.thumbnail,
              'C160x160',
            )
          "
          alt=""
        />
      </div>
      <div class="wrap_text">
        <div class="wrap_tags">
          <span
            v-for="(item, idx) in tagList"
            :key="`${idx}_tag`"
            v-html="
              totalSearchKeyword.searchWord
                ? makeTagHighlight(`#${totalSearchKeyword.searchWord}`, `#${item.name}`)
                : `#${item.name}`
            "
          ></span>
        </div>
        <strong>{{ props.cardData.title }}</strong>
      </div>
    </div>
    <span
      v-if="fileType"
      class="txt_link type_file"
      >다운로드</span
    >
    <span
      v-if="linkType"
      class="txt_link"
      >바로가기</span
    >
  </button>
  <!-- 정보 미수집시 -->
  <a
    v-else
    :href="linkType ? `${props.cardData.linkUrl}` : `${props.cardData.linkUrl}?download`"
    target="_blank"
    rel="noopener noreferrer"
    class="card_download"
    :data-tiara-action-name="`${tiaraName}_다운로드_클릭`"
    data-tiara-layer="download_button"
  >
    <div class="wrap_title">
      <div class="wrap_thumb">
        <img
          :src="
            generateWebpImage(
              props.cardData.thumbnail === ''
                ? 'https://t1.kakaocdn.net/dkt_corp/service/ico_nodata.png'
                : props.cardData.thumbnail,
              'C160x160',
            )
          "
          alt=""
        />
      </div>
      <div class="wrap_text">
        <div class="wrap_tags">
          <span
            v-for="(item, idx) in tagList"
            :key="`${idx}_tag`"
            v-html="
              totalSearchKeyword.searchWord
                ? makeTagHighlight(`#${totalSearchKeyword.searchWord}`, `#${item.name}`)
                : `#${item.name}`
            "
          ></span>
        </div>
        <strong>{{ props.cardData.title }}</strong>
      </div>
    </div>
    <span
      v-if="fileType"
      class="txt_link type_file"
      >다운로드</span
    >
    <span
      v-if="linkType"
      class="txt_link"
      >바로가기</span
    >
  </a>
</template>
<script setup>
import { useDownloadOpen, useTotalSearchKeyword } from '/stores/store';
const downloadOpen = useDownloadOpen();
const totalSearchKeyword = useTotalSearchKeyword();

const props = defineProps({
  cardData: {
    type: Object,
    default() {
      return {};
    },
  },
  listType: {
    type: String,
    default: '',
  },
});

const fileType = computed(() => {
  const type = props.cardData.infoType || props.cardData.type;
  return type === 'FILE';
});

const linkType = computed(() => {
  const type = props.cardData.infoType || props.cardData.type;
  return type === 'LINK';
});

const collectType = computed(() => {
  return props.cardData.collect;
});

const tagList = computed(() => {
  if (props.listType === 'search') {
    const tagArr = [];
    props.cardData.tag.forEach((ele) => {
      tagArr.push({ name: ele });
    });
    return tagArr;
  } else {
    return props.cardData.tag;
  }
});

const tiaraName = computed(() => {
  return props.cardData.title.replace(/ /g, '_');
});
</script>
<style lang="scss" scoped>
.card_download {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 31px 20px;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  text-align: left;
}
.wrap_title {
  display: flex;
  align-items: center;
}
.wrap_thumb {
  flex-shrink: 0;
  margin-right: 24px;
  @include setSize(80px, 80px);
  img {
    width: 100%;
    -webkit-mask-image: url('/public/thumb_cliping_img.png');
    -webkit-mask-mode: alpha;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
  }
}
.wrap_text {
  strong {
    display: -webkit-box;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}
.wrap_tags {
  overflow: hidden;
  width: 100%;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    font-size: 15px;
    line-height: 23px;
    word-break: break-all;
    & + span {
      margin-left: 8px;
    }
  }
}
.txt_link {
  display: inline-block;
  flex-shrink: 0;
  padding: 0 28px 0 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-decoration: underline;
  @include backgroundImage(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='M19 10.667V6m0 0h-4.667M19 6l-7 7m-1.556-7h-1.71c-1.307 0-1.96 0-2.46.254-.439.224-.796.581-1.02 1.02C5 7.774 5 8.427 5 9.734v6.533c0 1.306 0 1.96.254 2.459.224.439.581.796 1.02 1.02.5.254 1.153.254 2.46.254h6.533c1.306 0 1.96 0 2.459-.254.439-.224.796-.581 1.02-1.02.254-.5.254-1.152.254-2.46v-1.71' stroke='%23202020' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E",
    24px 24px,
    top 0 right 0
  );
  &.type_file {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath d='M19 15.333v.934c0 1.306 0 1.96-.254 2.459-.224.439-.581.796-1.02 1.02-.5.254-1.152.254-2.46.254H8.734c-1.306 0-1.96 0-2.459-.254a2.334 2.334 0 0 1-1.02-1.02C5 18.226 5 17.574 5 16.266v-.933m10.889-3.889L12 15.334m0 0-3.889-3.89M12 15.334V6' stroke='%234C4C4C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
@media screen and (max-width: 811px) {
  .card_download {
    height: 122px;
    padding: 20px;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .wrap_thumb {
    display: none;
  }
  .wrap_text {
    strong {
      font-size: 16px;
    }
  }
  .wrap_tags {
    span {
      font-size: 14px;
    }
  }
  .txt_link {
    margin-top: 12px;
    padding: 0 24px 0 0;
    font-size: 14px;
    background-size: 20px 20px;
    background-position: top 0px right 0;
  }
}
</style>
