<template>
  <div
    v-if="props.listData.length > 0"
    class="wrap_list"
  >
    <ul
      :class="[
        'list_cate',
        { type_download: props.title === '자료실' },
        { type_main_double: isMainDouble },
        { type_main: props.page === 'main' },
      ]"
    >
      <li
        v-for="(item, idx) in props.listData"
        :key="`li_${idx}`"
      >
        <CardDownload
          v-if="props.title === '자료실'"
          :cardData="item"
          :listType="props.listType"
        />
        <CardCategory
          v-else
          :cardData="item"
          :cardType="cardType"
          :page="isMainDouble ? 'typeDouble' : ''"
        />
      </li>
    </ul>

    <div
      v-if="dataMore.isMore"
      class="wrap_btn"
    >
      <button
        type="button"
        @click="getMoreList"
      >
        더보기 ({{ props.dataMore.currPage }}/{{ props.dataMore.endPage }})
      </button>
    </div>
  </div>
  <div
    v-else
    class="wrap_nolist"
  >
    <span>등록된 콘텐츠가 없습니다.</span>
  </div>
</template>
<script setup>
const emit = defineEmits(['doSearchMore']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  listData: {
    type: Array,
    default() {
      return [];
    },
  },
  dataMore: {
    type: Object,
    default() {
      return {};
    },
  },
  page: {
    type: String,
    default: '',
  },
  listType: {
    type: String,
    default: '',
  },
});

const cardType = computed(() => {
  switch (props.title) {
    case '고객사례':
      return 'case';
    case '뉴스':
      return 'news';
    default:
      return '';
  }
});

const isMainDouble = computed(() => {
  return props.page === 'main' && props.listData.length === 2;
});

const getMoreList = () => {
  emit('doSearchMore', props.dataMore.currPage + 1);
};
</script>

<style lang="scss" scoped>
.list_cate {
  max-width: 1244px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 60px;
  column-gap: 24px;
  &.type_download {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  &.type_main_double {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}
.wrap_nolist {
  position: relative;
  height: 100%;
  text-align: center;
  color: #999;
  font-size: 18px;
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.wrap_btn {
  margin-top: 60px;
  text-align: center;
  button {
    height: 40px;
    padding: 0 28px 0 8px;
    font-size: 16px;
    line-height: 42px;
    color: #5887ff;
    @include backgroundImage(
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none'%3E%3Cpath d='m3.5 5 5 5 5-5' stroke='%235887FF' stroke-width='1.5'/%3E%3C/svg%3E",
      16px 16px,
      top 12px right 8px
    );
  }
}
@media screen and (max-width: 1632px) {
  .list_cate {
    grid-template-columns: 1fr 1fr;
    &.type_main {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.type_main_double {
      grid-template-columns: 1fr 1fr;
    }
    &.type_download {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 1439px) {
  .list_cate {
    &.type_main_double {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media screen and (max-width: 1023px) {
  .list_cate {
    &.type_main {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
    }
  }
  .wrap_nolist {
    height: calc(100vh - 80px);
  }
}
@media screen and (max-width: 811px) {
  .list_cate,
  .list_cate.type_main_double,
  .list_cate.type_main {
    grid-template-columns: 1fr;
  }
  .wrap_nolist {
    height: calc(100vh - 60px);
  }
}
</style>
